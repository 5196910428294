@import "bootstrap/scss/bootstrap";
@import "~@fortawesome/fontawesome-free/css/all.css";
@import 'ngx-toastr/toastr';

html {
  width: 100%;
  height: 100%;

  body {
    width: 100%;
    height: 100%;
    margin: 0;

    .btn-assign {
      cursor: pointer;
    }

    .btn-assign:hover {
      text-decoration: underline;
    }

    .logo-header {
      margin: 1em;
    }


  }
}

.button-loader {
  width: 17px;
  height: 17px;
  border-width: 0.2rem;
  display: inline-block;
  vertical-align: middle;
  border: 0.2rem solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: spinner-border 0.75s linear infinite;
  -webkit-animation: spinner-border 0.75 linear infinite;
}

@keyframes spinner-border {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

// Correct bootstrap style for modals

.modal-backdrop {
  z-index: 1050 !important;
}

.table {
  td {
    vertical-align: middle;

    .btn {
      &.btn-link {
          padding: 0;
          text-align: left;
      }
    }
  }
}


// Modify size of checkbox for input type check

.form-check {
  padding-left: 1.75rem;

  .form-check-input {
    margin-top: 0;
    margin-left: -1.75rem;
    width: 1.5em;
    height: 1.5em;
  }
}

